import { useState } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { IoPersonCircleSharp } from "react-icons/io5";
import UserInfo from "containers/UserInfo";
import { ReactComponent as UndoIcon } from "assets/icons/undo.svg";
import TranscriptIcon from "assets/icons/transcript";
import LightBulbIcon from "assets/icons/bulb";
import LiveTranscription from "components/LiveTranscriptionV2";
import KnowledgeBaseSidebar from "containers/KnowledgeBaseSidebar";
import styles from "./styles";

export default function Drawer({
  open,
  customer,
  setCustomer,
  state,
  transcript,
  setTranscript,
  boxRef,
  selectedTab,
  setSelectedTab,
  }) {

  const [history, setHistory] = useState(["search"]);

  const tabs = [
    {
      name: "knowledge-base",
      icon: LightBulbIcon,
    },
    {
      name: "customer",
      icon: IoPersonCircleSharp,
    },
    {
      name: "transcript",
      icon: TranscriptIcon,
    },
  ];

  return (
    <Box sx={{ ...styles.root, display: open ? "block" : "none" }}>
      <Box sx={styles.topRow}>
        {tabs.map((tab) => (
          <Box
            key={tab.name}
            sx={{
              ...(selectedTab === tab.name ? styles.selectedIconButton : {}),
              height: "100%",
            }}
          >
            <IconButton
              sx={styles.iconButton}
              onClick={() => setSelectedTab(tab.name)}
            >
              <tab.icon color={ selectedTab === tab.name ? "#6552F3" : "#98A2B3"} />
            </IconButton>
          </Box>
        ))}
      </Box>

      <Box sx={styles.contentArea}>
        {selectedTab === "knowledge-base" && (
          <Box sx={{ width: "100%" }}>
            <KnowledgeBaseSidebar />
          </Box>
        )}

        {selectedTab === "customer" && (
          <Box>
            <Typography sx={styles.title}>
              Customer Details
            </Typography>

            {history.length > 1 && (
              <IconButton
                sx={{ fontSize: "20px", color: "gray.700" }}
                onClick={() => {
                  setHistory(history.slice(0, history.length - 1));
                  setCustomer(null);
                }}
              >
                <UndoIcon />
              </IconButton>
            )}

            <UserInfo
              history={history}
              setHistory={setHistory}
              user={customer}
              setUser={setCustomer}
            />
          </Box>
        )}

        {selectedTab === "transcript" && (
          <Box>
              <LiveTranscription
                state={state}
                transcript={transcript}
                setTranscript={setTranscript}
                boxRef={boxRef}
              />
          </Box>
        )}
      </Box>
    </Box>
  );
}
