const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    border: "1px solid #D0D5DD",
    borderRadius: "16px",
    paddingY: "6px",
    paddingX: "10px",
    minWidth: "300px",
  },

  starContainer: {
    display: "flex",
    mt: "10px",
    ml: "5px",
  },

  questionContainer: {
    pt: "10px",
    pb: "10px",
    ml: "10px",
  },
  questionText: {
    fontSize: "16px",
    fontWeight: 500,
    color: "#10181E",
  },

  inputContainer: {
    mx: "10px",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    }
  },
  inputField: {
    border: "1px solid #E3E6EA",
    borderRadius: "16px",
    "& .MuiInputBase-input": {
      color: "#667085",
      fontSize: "14px",
    },
    width: "100%",
  },

  buttonContainer: {
    display: "flex",
    mt: "20px",
    mb: "10px",
    mr: "10px",
  },
  submitButton: {
    ml: "auto",
    height: "35px",
    minWidth: "80px",
    fontSize: "13px",
    fontWeight: "700px",
    px: "10px",
    py: "10px",
  },
};

export default styles;
