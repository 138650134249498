import { Box, Container, Divider, Typography } from "@mui/material";
import styles from "./styles";
import loginForm from "./data";
import FormInput from "components/FormInput";
import { useFormik } from "formik";
import { useContext, useState } from "react";
import { LoadingButton } from "@mui/lab";
import Header from "components/Header";
import { login } from "services/api";
import { AppContext } from "App";
import { useNavigate } from "react-router-dom";

export default function Login() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { notifyError } = useContext(AppContext);
  const { init, schema, fields } = loginForm;

  const handleSubmit = async (values) => {
    setLoading(true);
    const authToken = await login(values).catch((error) => {
      notifyError(error.message, 4000);
    });

    if (!authToken) {
      setLoading(false);
      return;
    }

    localStorage.setItem("authToken", authToken);
    navigate("/");
  };

  const formik = useFormik({
    initialValues: init(),
    validationSchema: schema,
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit: handleSubmit,
  });

  return (
    <div>
      <Header />

      <Container maxWidth="mlg" sx={styles.main}>
        <Box
          sx={styles.actionBox}
          component="form"
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
          }}
        >
          <Box sx={{ mb: "32px" }}>
            <Typography component="h1" fontWeight={900} variant="h4">
              Log in
            </Typography>

            <Typography variant="body-lg" color="gray.500" sx={{ mt: "12px" }}>
              Thousands of creators saved 200K+ hours with Deciphr
            </Typography>
          </Box>

          <Box sx={{ "& > *:not(:last-child)": { mb: "12px" }, mb: "24px" }}>
            {fields.map((field) => (
              <FormInput key={field.id} {...field} formik={formik} />
            ))}
          </Box>

          <Divider sx={{ borderColor: "gray.300", mb: "40px" }} />

          <LoadingButton
            loading={loading}
            type="submit"
            variant="contained"
            sx={styles.loginButton}
          >
            Log In
          </LoadingButton>
        </Box>
      </Container>
    </div>
  );
}
