const styles = {
  questionBox: {
    width: "50%",
    py: "20px",
    px: { md: "30px", xs: "20px" },
    borderRadius: "24px",
    color: "gray.600",
    bgcolor: "#F1F0FF",
    ml: "auto",
    mb: "20px",
  },

  questionText: {
    fontSize: "16px",
    fontWeight: 500,
    lineGeight: "23px",
    color: "#6552F3",
  },

  responseBox: {
    width: "70%",
    py: "20px",
    px: { md: "30px", xs: "20px" },
    borderRadius: "24px",
    border: "1px solid #E3E6EA",
    color: "gray.600",
    bgcolor: "#FCFCFD",
    mb: "20px",
  },

  responseText: {
    fontSize: "16px",
    fontWeight: 500,
    lineGeight: "23px",
    color: "#475467",
  },

  actions: {
    mt: "10px",
    display: "flex",
    justifyContent: "start",
    gap: "8px",
    color: "gray.400",
  },

  tooltip: {
    popper: {
      modifiers: [
        {
          name: "offset",
          options: { offset: [0, -4] },
        },
      ],
    },
    tooltip: {
      sx: {
        bgcolor: "gray.700",
        fontSize: "14px",
        borderRadius: "8px",
        padding: "4px 10px",
      },
    },
  },

  iconButton: {
    padding: "6px",
    color: "inherit",
  },

  iconBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "24px",
    width: "24px",
    fontSize: "24px",
  },

  menu: {
    "& .MuiPaper-root": {
      padding: "12px",
      mt: "12px",
      boxShadow: "none",
      borderRadius: "16px",
      border: "1px solid",
      borderColor: "gray.300",
      minWidth: "160px",
    },

    "& .MuiList-root": {
      padding: 0,
    },
  },

  menuItem: {
    padding: "12px",
    borderRadius: "8px",
  },
};

export default styles;
