const styles = {
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "32px",
    // flex: "1 1 55%",
    justifyContent: "space-between",
  },

  chatBox: {
    padding: "36px",
    borderRadius: "24px",
    // height: "672px",
    width: "100%",
    overflowY: "auto",
    height: "100%",
  },

  recordButton: {
    fontSize: "18px",
    mx: "auto",
    minWidth: "160px",
    py: "20px",
    mt: "40px",
  },

  emptyStateBox: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },

  questionInputContainer: {
    width: "80%",
    mx: "auto",
  },
};

export default styles;
