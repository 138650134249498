import { useState } from "react";
import Box from "@mui/material/Box";
import TopNavBar from "components/TopNavBar";
import SideNavBar from "components/SideNavBar";
import styles from "./styles";

export default function WhiteHeaderSidebarLayout(props) {
  const { handleReset, leftDrawer, children } = props;

  const [expanded, setExpanded] = useState(false);

  const handleExpandToggle = () => {
    setExpanded((current) => !current);
  };

  return (
    <Box sx={styles.root}>
      <Box sx={styles.navbarRow}>
        <TopNavBar handleReset={handleReset} />
      </Box>

      <Box sx={styles.contentRow}>
        <Box sx={styles.sideBarContainer}>
          <SideNavBar
            expanded={expanded}
            onExpandToggle={handleExpandToggle}
          />
        </Box>

        <Box
          sx={{
            ...styles.drawer,
            display: expanded ? "block" : "none"
          }}
        >
          {leftDrawer}
        </Box>

        <Box sx={styles.children}>
          {children}
        </Box>
      </Box>

    </Box>
  );
}
