import { Menu, MenuItem } from "@mui/material";
import { FaCheckCircle } from "react-icons/fa";

export default function CustomSelect({
  value,
  anchorEl,
  setAnchorEl,
  options,
  onSelect,
  onClose,
}) {
  return (
    <Menu
      anchorEl={anchorEl}
      open={!!anchorEl}
      onClose={onClose}
      sx={{
        mt: "16px",

        "& .MuiPaper-root": {
          borderRadius: "16px",
          border: "1px solid",
          borderColor: "gray.300",
          boxShadow: "none",
          minWidth: "200px",
        },

        "& .MuiList-root": {
          padding: "12px",
        },
      }}
    >
      {options.map((option) => (
        <MenuItem
          key={option.id}
          sx={{
            fontWeight: value === option.id ? 700 : 500,
            color: value === option.id ? "primary.main" : "inherit",
            padding: "12px",
            borderRadius: "8px",
            fontSize: "18px",
            display: "flex",
            justifyContent: "space-between",
            gap: "12px",

            "&:hover": {
              backgroundColor: "primary.100",
            },

            "&:focus": {
              backgroundColor: "primary.50",
            },
          }}
          onClick={() => {
            onSelect(option);
            setAnchorEl(null);
          }}
        >
          <span>{option.name}</span>
          {value === option.id && <FaCheckCircle />}
        </MenuItem>
      ))}
    </Menu>
  );
}
