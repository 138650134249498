import AssistantFlow from "containers/AssistantFlow";
import Login from "containers/Login";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import AssistantFlowV2 from "containers/AssistantFlowV2";

function ProtectedRoutes() {
  const loggedOutState = {
    referrer: window.location.pathname,
    message: "You must sign in to your Deciphr Brain account to continue.",
  };

  const authToken = localStorage.getItem("authToken");

  if (!authToken) {
    return <Navigate to="/auth/login" state={loggedOutState} replace />;
  }

  const encodedPayload = authToken.split(".")[1];
  const payload = JSON.parse(atob(encodedPayload));
  const msExpiry = payload.expiry * 1000;

  // Check logged in status and token validity
  if (msExpiry < new Date()) {
    localStorage.removeItem("userInfo");
    return <Navigate to="/auth/login" state={loggedOutState} replace />;
  }

  return <Outlet />;
}

function AuthRoutes() {
  const authToken = localStorage.getItem("authToken");

  if (authToken !== null) {
    const encodedPayload = authToken.split(".")[1];
    const payload = JSON.parse(atob(encodedPayload));
    const msExpiry = payload.expiry * 1000;

    if (msExpiry > new Date()) {
      return <Navigate to="/" replace />;
    }
  }

  return <Outlet />;
}

export default function DARoutes() {
  return (
    <Routes>
      <Route element={<ProtectedRoutes />}>
        <Route path="/" element={<AssistantFlow />} />
      </Route>
      <Route element={<AuthRoutes />}>
        <Route path="/auth/login" element={<Login />} />
      </Route>
      <Route element={<ProtectedRoutes />}>
        <Route path="/v2" element={<AssistantFlowV2 />} />
      </Route>
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
}
