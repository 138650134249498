const styles = {
  root: {
    display: "flex",
    height: "100%",
    alignItems: "center",
    pl: "20px",
  },

  resetButton: {
    ml: "auto",
    mr: "20px",
    border: "1px solid #E3E6EA",
    "& svg": { transform: "rotateY(180deg)" },
  },

  userButton: {
    mr: "20px",
  },
};

export default styles;
