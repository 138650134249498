import { configureStore } from "@reduxjs/toolkit";
import chatReducer from "./slices/chatSlice";
import knowledgeBaseReducer from "./slices/knowledgeBaseSlice";

export const store = configureStore({
  reducer: {
    chat: chatReducer,
    knowledgeBase: knowledgeBaseReducer,
  },
});
