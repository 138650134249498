import { Alert, Box, IconButton, Slide, Snackbar } from "@mui/material";
import { FaRegCircleCheck } from "react-icons/fa6";
import { IoIosWarning } from "react-icons/io";
import { IoCloseCircleOutline } from "react-icons/io5";

export default function Toast({ toast, setToast }) {
  const handleClose = () => {
    setToast({ severity: "", message: "" });
  };

  return (
    <div>
      <Snackbar
        autoHideDuration={toast.duration}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={toast.message !== ""}
        TransitionComponent={(props) => <Slide {...props} direction="up" />}
        onClose={handleClose}
        sx={{
          "&.MuiSnackbar-anchorOriginBottomRight": {
            right: "80px",
          },
        }}
      >
        <Alert
          severity={toast.severity}
          variant="outlined"
          sx={{
            width: "100%",
            borderRadius: 999,
            bgcolor: "#fff",
            boxShadow: "0 0 16px 0 rgba(0,0,0,0.34)",

            "& .MuiAlert-icon": {
              alignItems: "center",
            },
          }}
          iconMapping={{
            success: <FaRegCircleCheck size="1em" />,
            error: <IoIosWarning size="1em" />,
          }}
        >
          <Box
            sx={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
            }}
          >
            {toast.message}
            <IconButton onClick={handleClose} sx={{ marginLeft: "15px" }}>
              <IoCloseCircleOutline />
            </IconButton>
          </Box>
        </Alert>
      </Snackbar>
    </div>
  );
}
