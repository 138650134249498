const styles = {
  root: {
    display: "flex",
    flexDirection: "column",
    gap: "32px",
    flex: "1 1 55%",
  },

  chatBox: {
    padding: "36px",
    borderRadius: "24px",
    border: "1px solid",
    borderColor: "gray.300",
    height: "672px",
    width: "100%",
    overflowY: "auto",
    "& > *:not(:first-of-type)": {
      mt: "40px",
    },
  },

  skeleton: {
    fontSize: { md: "1.5rem", sm: "1.25rem", xs: "1.25rem" },
  },

  generatingBox: {
    width: "100%",
    padding: "20px 36px",
    borderRadius: "24px",
    border: "1px solid",
    borderColor: "gray.300",
    color: "gray.600",
    bgcolor: "primary.50",
    mt: "24px",
  },
};

export default styles;
