import { Box, Button } from "@mui/material";
import { useState } from "react";
import CustomSelect from "components/CustomSelect";
import { IoIosArrowDown } from "react-icons/io";

export default function HeaderSelect({ value, onSelect, sx, assistants }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [label, setLabel] = useState("Default");

  return (
    <>
      <Button
        onClick={(e) => setAnchorEl(e.currentTarget)}
        endIcon={<IoIosArrowDown size="1.1em" />}
        sx={{
          borderRadius: 99,
          bgcolor: "primary.50",
          color: "inherit",
          fontSize: { sm: "18px", xs: "16px" },
          padding: "14px 18px",
          textAlign: "left",

          "& svg": {
            color: "gray.400",
          },

          "&:hover": {
            bgcolor: "primary.100",
          },
          ...sx,
        }}
      >
        <Box
          sx={{
            width: "144px",
            justifyContent: "start",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
        >
          {label}
        </Box>
      </Button>

      <CustomSelect
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        onClose={() => setAnchorEl(null)}
        value={value}
        options={assistants}
        onSelect={({ id, name }) => {
          onSelect(id);
          setLabel(name);
        }}
      />
    </>
  );
}
