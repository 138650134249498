const styles = {
  title: {
    fontSize: "20px",
    fontWeight: 700,
    color: "#1B2730",
  },

  selectBrainDropdown: {
    background: "#fff",
    borderRadius: "10px",
    border: "1px solid #D0D5DD",

    fieldset: {
      border: "none",
    },
  },

  accordionContainer: {
    mt: "20px",
    border: "1px solid rgba(227, 230, 234, 1)",
    borderRadius: "16px",
    p: "10px",
    background: "#fff",
  },
  accordion: {
    boxShadow: "none",
  },
  accordionSummary: {
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "26px",
  },

  inputContainer: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
  inputField: {
    width: "100%",
    minHeight: "200px",
    borderRadius: "16px",
    border: "1px solid #D0D5DD",
  },

  submitButton: {
    backgroundColor: "#6552F3",
    height: "36px",
    mt: "20px",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "23px",
  },
};

export default styles;
