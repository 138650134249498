import { useState } from "react";
import Popover from "@mui/material/Popover";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import { ReactComponent as StarIconOutline } from "assets/icons/star-outline-gray.svg";
import { ReactComponent as StarIconSolidGold } from "assets/icons/star-solid-gold.svg";
import { ReactComponent as SendIcon } from "assets/icons/send.svg";
import styles from "./styles";

function RateAnswer(props) {
  const { popoverId, open, anchorEl, onClose, rating, submitRating } = props;

  const [hoverIndex, setHoverIndex] = useState(null);
  const [currentRating, setCurrentRating] = useState(rating);
  const [feedback, setFeedback] = useState("");

  const handleSubmit = () => {
    submitRating(currentRating, feedback);
    handlePopoverExit();
  };

  const handleStarClick = (i) => {
    setCurrentRating(i);
  };

  const handleStarHover = (i) => {
    setHoverIndex(i);
  };

  const handlePopoverExit = () => {
    setHoverIndex(null);
    onClose();
  }

  const handleFeedbackChange = (e) => {
    setFeedback(e.target.value);
  };

  return (
    <Popover
      id={popoverId}
      open={open}
      anchorEl={anchorEl}
      onClose={handlePopoverExit}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      elevation={0}
    >
      <Box sx={styles.container}>
        <Box sx={styles.starContainer}>
          {currentRating !== null ? (
            <>
              {[...Array(5).keys()].map(i => (
                <Box key={i} onClick={() => handleStarClick(i)}>
                  <IconButton>
                    {i <= currentRating ? (
                      <StarIconSolidGold />
                    ) : (
                      <StarIconOutline />
                    )}
                  </IconButton>
                </Box>
              ))}
            </>
          ) : (
            <>
              {[...Array(5).keys()].map(i => (
                <Box
                  key={i}
                  onMouseOver={() => handleStarHover(i)}
                  onClick={() => handleStarClick(i)}
                >
                  <IconButton>
                    {hoverIndex !== null && i <= hoverIndex ? (
                      <StarIconSolidGold />
                    ) : (
                      <StarIconOutline />
                    )}
                  </IconButton>
                </Box>
              ))}
            </>
          )}
        </Box>

        <Box sx={styles.questionContainer}>
          <Typography sx={styles.questionText}>
            How was the assistant?
          </Typography>
        </Box>

        <Box sx={styles.inputContainer}>
          <TextField
            sx={styles.inputField}
            multiline
            maxRows={4}
            value={feedback}
            onChange={handleFeedbackChange}
          />
        </Box>

        <Box sx={styles.buttonContainer}>
          <LoadingButton
            variant="contained"
            endIcon={<SendIcon />}
            sx={styles.submitButton}
            onClick={handleSubmit}
          >
            Send
          </LoadingButton>
        </Box>
      </Box>
    </Popover>
  );
}

export default RateAnswer;
