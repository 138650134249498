const styles = {
  main: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "40px",
    pt: "56px",
    pb: "96px",

    "@media screen and (max-width: 800px)": {
      minWidth: "100%",
    },
  },

  actionBox: {
    padding: { xs: "32px", lg: "48px" },
    border: "1px solid",
    borderColor: "gray.200",
    borderRadius: "20px",
    boxShadow: "0 2px 6px 0 rgba(0, 0, 0, 0.06)",
    width: "100%",
    bgcolor: "#fff",
    maxWidth: "480px",
  },

  loginButton: {
    width: "100%",
    fontSize: { xs: "14px", lg: "18px" },
    fontWeight: 700,
    py: "18px",
  },
};

export default styles;
