const styles = {
  root: {
    position: "relative",
    maxWidth: "100vw",
    overflowX: "hidden",
  },

  iconButton: {
    fontSize: "18px",
    color: "gray.700",
    border: "1px solid",
    borderColor: "gray.300",
    "& svg": { transform: "rotateY(180deg)" },
  },

  mobileSelect: {
    my: "40px",
    display: { sm: "none", xs: "block" },
  },

  mainScrollable: {
    maxHeight: "calc(100vh - 115px)",
    overflowY: "auto",
    transition: "width 0.3s ease-in-out",
    position: "relative",
  },

  main: {
    display: "flex",
    flexDirection: { md: "row", xs: "column" },
    gap: "32px",
    py: "32px",
  },

  openDrawerButton: {
    position: "absolute",
    top: "32px",
    right: "32px",
    border: "1px solid #E3E6EA",
  },
};

export default styles;
