import { Box, Container, Divider, Typography } from "@mui/material";
import FrequentQuestion from "components/FrequentQuestion";
import SummaryInfo from "components/SummaryInfo";

export default function Summary({ chatHistory, summary }) {
  return (
    <Container
      maxWidth="mlg"
      sx={{ "& > *:not(:first-of-type)": { mt: "64px" }, py: "56px" }}
    >
      <SummaryInfo />

      <div>
        <Typography sx={{ mb: "32px" }} variant="h7" fontWeight={900}>
          Summary
        </Typography>

        <Typography
          sx={{ mb: "32px" }}
          variant="body-lg"
          lineHeight={2}
          color="gray.600"
        >
          {summary}
        </Typography>
      </div>

      <Divider />

      <Box sx={{ display: "flex", gap: "40px", flexWrap: "wrap" }}>
        <Typography variant="h7" fontWeight={900} sx={{ flex: "0 0 100%" }}>
          Most asked questions
        </Typography>

        <div style={{ flex: 1 }}>
          {chatHistory
            .slice(0, Math.ceil(chatHistory.length / 2))
            .map((c, index) => (
              <FrequentQuestion key={index} {...c} />
            ))}
        </div>

        <Divider orientation="vertical" flexItem />

        <div style={{ flex: 1 }}>
          {chatHistory
            .slice(Math.ceil(chatHistory.length / 2))
            .map((c, index) => (
              <FrequentQuestion key={index} {...c} />
            ))}
        </div>
      </Box>
    </Container>
  );
}
