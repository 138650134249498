import * as Yup from "yup";

const loginForm = {
  fields: [
    {
      id: "email",
      size: 1,
      label: "Email",
      type: "text",
      placeholder: "Email address",
      required: true,
      disabled: false,
    },
    {
      id: "password",
      size: 1,
      label: "Password",
      type: "password",
      placeholder: "Enter your Password",
      required: true,
      disabled: false,
    },
  ],

  init: () => ({
    email: "",
    password: "",
  }),

  schema: Yup.object().shape({
    email: Yup.string()
      .email("Enter a valid email address")
      .required("This field is required"),
    password: Yup.string().required("This field is required"),
  }),
};

export default loginForm;
