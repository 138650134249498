import { Box, Typography } from "@mui/material";
import { IoIosArrowForward } from "react-icons/io";

export default function UserList({ users, setUser, setHistory }) {
  if (users.length === 0) {
    return (
      <Box
        sx={{
          bgcolor: "#fff",
          borderRadius: "16px",
          border: "1px solid #E3E6EA",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          padding: "48px",
        }}
      >
        <img src="assets/images/no-result.png" alt="No result" width="90%" />

        <Typography variant="h8" fontWeight={700} sx={{ mt: "24px" }}>
          No Matches Found
        </Typography>

        <Typography variant="body" color="gray.500" sx={{ mt: "12px" }}>
          We couldn't find any matching customer records.
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <Typography variant="body" color="gray.500" sx={{ mb: "24px" }}>
        We found multiple matches for this email. Please select the customer
        from the list below.
      </Typography>

      <Box
        sx={{
          bgcolor: "#fff",
          borderRadius: "16px",
          border: "1px solid #E3E6EA",
        }}
      >
        {users.map((user, idx) => (
          <Box
            key={idx}
            onClick={() => {
              setUser(user);
              setHistory((prev) => [...prev, "show-info"]);
            }}
            sx={{
              padding: "24px",
              borderBottom:
                idx === users.length - 1 ? "0" : "1px solid #E3E6EA",
              display: "flex",
              justifyContent: "space-between",
              gap: "16px",

              "&:hover": {
                cursor: "pointer",
                backgroundColor: "#f9f9f9",
              },
            }}
          >
            <div>
              <Typography variant="h8" fontWeight={700} sx={{ mb: "20px" }}>
                {user.first_name} {user.last_name}
              </Typography>

              <Typography
                variant="body-lg"
                color="gray.600"
                sx={{ mb: "16px" }}
              >
                {user.email}
              </Typography>

              <Typography variant="body-lg" color="gray.600">
                {user.phone_number}
              </Typography>
            </div>

            <Box sx={{ my: "2px", color: "gray.500" }}>
              <IoIosArrowForward size="24px" />
            </Box>
          </Box>
        ))}
      </Box>
    </>
  );
}
