import axios from "./axios.config";

export const login = async (values) => {
  try {
    const { data } = await axios({
      method: "POST",
      url: "/auth/email/login",
      headers: {
        "Content-Type": "application/json",
      },
      data: values,
    });

    return data.token;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const getAAIToken = async () => {
  try {
    const { data } = await axios({
      method: "GET",
      url: "/chat/assembly/token",
    });

    return data.token;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getDeepgramToken = async () => {
  try {
    const { data } = await axios({
      method: "GET",
      url: "/chat/deepgram/token",
    });

    return data.token;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const checkQuestionThread = async (transcript, threadId, model) => {
  try {
    const { data } = await axios({
      method: "POST",
      url: "/chat/check-question-thread",
      headers: {
        "Content-Type": "application/json",
      },
      data: { transcript, thread_id: threadId, model },
    });

    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const askLLM = async (question, model, threadId, size) => {
  try {
    const authToken = localStorage.getItem("authToken");

    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/chat/ask-llm-thread`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({ question, thread_id: threadId, size, model }),
      }
    );

    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const askBrain = async (question, assistant, threadId, size, customerId) => {
  try {
    const authToken = localStorage.getItem("authToken");

    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/chat/ask-brain`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({
          question,
          persona_id: assistant,
          thread_id: threadId,
          size,
          user_id: customerId,
        }),
      }
    );

    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const fetchBrains = async () => {
  try {
    const { data } = await axios({
      method: "GET",
      url: "https://staging-brain.api.deciphr.ai/cbo/persona",
    });

    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const saveChat = async (transcript, threadId, model) => {
  try {
    if (threadId) {
      const { data } = await axios({
        method: "POST",
        url: "/chat/history",
        headers: {
          "Content-Type": "application/json",
        },
        data: { transcript, thread_id: threadId, model, agent: "" },
      });

      return data.summary;
    } else {
      return "";
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const searchUsers = async (query) => {
  try {
    const { data } = await axios({
      method: "GET",
      url: "/customers/search",
      params: { query },
    });

    return data.customers;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const rateAnswer = async (payload) => {
  try {
    await axios({
      method: "POST",
      url: "/chat/rate",
      headers: {
        "Content-Type": "application/json",
      },
      data: payload,
    });
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getSessions = async () => {
  try {
    const { data } = await axios({
      method: "GET",
      url: "/session",
    });
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getSessionMessages = async (threadId) => {
  try {
    const { data } = await axios({
      method: "GET",
      url: `/session/${threadId}`,
    });
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};
