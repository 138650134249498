import { Box, IconButton, Typography } from "@mui/material";
import UserInfo from "containers/UserInfo";
import { useState } from "react";
import { ReactComponent as UndoIcon } from "assets/icons/undo.svg";

export default function UserInfoDrawer({ open, user, setUser }) {
  const [history, setHistory] = useState(["search"]);

  return (
    <Box
      sx={{
        position: "absolute",
        width: "360px",
        bgcolor: "#F9FAFB",
        borderLeft: "1px solid #DCE0E5",
        height: "100%",
        bottom: 0,
        right: 0,
        transform: open ? "translateX(0)" : "translateX(100%)",
        transition: "transform 0.3s ease-in-out",
        overflowY: "auto",
      }}
    >
      <Box
        sx={{
          padding: "32px",
          borderBottom: "1px solid #DCE0E5",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "16px",
        }}
      >
        <Typography variant="h8" fontWeight={700}>
          Customer Details
        </Typography>

        {history.length > 1 && (
          <IconButton
            sx={{ fontSize: "20px", color: "gray.700" }}
            onClick={() => {
              setHistory(history.slice(0, history.length - 1));
              setUser(null);
            }}
          >
            <UndoIcon />
          </IconButton>
        )}
      </Box>

      <UserInfo
        history={history}
        setHistory={setHistory}
        user={user}
        setUser={setUser}
      />
    </Box>
  );
}
