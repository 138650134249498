const styles = {
  responseBox: {
    width: "100%",
    py: "20px",
    px: { md: "36px", xs: "24px" },
    borderRadius: "24px",
    border: "1px solid",
    borderColor: "gray.300",
    color: "gray.600",
    bgcolor: "primary.50",
  },

  actions: {
    mt: "8px",
    display: "flex",
    justifyContent: "end",
    gap: "8px",
    color: "gray.400",
  },

  tooltip: {
    popper: {
      modifiers: [
        {
          name: "offset",
          options: { offset: [0, -4] },
        },
      ],
    },
    tooltip: {
      sx: {
        bgcolor: "gray.700",
        fontSize: "14px",
        borderRadius: "8px",
        padding: "4px 10px",
      },
    },
  },

  iconButton: {
    padding: "6px",
    color: "inherit",
  },

  iconBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "24px",
    width: "24px",
    fontSize: "24px",
  },

  menu: {
    "& .MuiPaper-root": {
      padding: "12px",
      mt: "12px",
      boxShadow: "none",
      borderRadius: "16px",
      border: "1px solid",
      borderColor: "gray.300",
      minWidth: "160px",
    },

    "& .MuiList-root": {
      padding: 0,
    },
  },

  menuItem: {
    padding: "12px",
    borderRadius: "8px",
  },
};

export default styles;
