const styles = {
  stopButton: {
    fontSize: "18px",
    mx: "auto",
    minWidth: "120px",
    p: "13px",
  },
  stopIcon: {
    height: "18px",
    width: "18px",
    borderRadius: "4px",
    bgcolor: "#fff",
  },

  recordButton: {
    border: "1px solid #E3E6EA",
    p: "10px",
  },

  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    mb: "50px",
  },

  submitButton: {
    p: "10px",
    backgroundColor: "#6552F3",
    mr: "8px",
    "&:hover": {
      backgroundColor: "#4A3BC0",
    },
  },

  inputArea: {
    border: "1px solid red",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "54px",
    border: "1px solid #D0D5DDCC",
    width: "100%",
    textarea: {
      border: "none",
    },
  },
};

export default styles;
