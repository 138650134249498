import Toast from "components/Toast";
import { createContext, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import DARoutes from "hoc/routes";
import { store } from "store/store";

export const AppContext = createContext();

function App() {
  const [toast, setToast] = useState({ severity: "", message: "" });

  const notifyError = (message, duration) => {
    setToast({ severity: "error", message, duration });
  };

  const value = {
    toast,
    notifyError,
  };

  return (
    <Provider store={store}>
      <BrowserRouter>
        <AppContext.Provider value={value}>
          <DARoutes />
          <Toast toast={toast} setToast={setToast} />
        </AppContext.Provider>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
