import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#6552F3",
      contrastText: "#fff",
      50: "#F6F7F9",
      100: "#F5F4FF",
      200: "#DDD9FF",
      300: "#B5B3FF",
      400: "#8980ff",
      500: "#6552F3",
      600: "#5C4CD8",
      700: "#3E2FAF",
      800: "#261672",
    },
    gray: {
      main: "#667085",
      contrastText: "#fff",
      100: "#FCFCFD",
      200: "#F2F4F7",
      300: "#D0D5DD",
      400: "#98A2B3",
      500: "#667085",
      600: "#475467",
      700: "#1B2730",
      800: "#10181E",
    },
    error: {
      main: "#F04438",
      contrastText: "#fff",
      100: "#FEF3F2",
      200: "#FECDCA",
      300: "#FDA29B",
      400: "#F97066",
      500: "#F04438",
      600: "#D92D20",
      700: "#B42318",
      800: "#912018",
    },
    warning: {
      main: "#FDB022",
      contrastText: "#fff",
      100: "#FFFCF5",
      200: "#FEF0C7",
      300: "#FEDF89",
      400: "#FEC84B",
      500: "#FDB022",
      600: "#F79009",
      700: "#DC6803",
      800: "#B54708",
    },
    success: {
      main: "#12B76A",
      contrastText: "#fff",
      100: "#ECFDF3",
      200: "#D1FADF",
      300: "#6CE9A6",
      400: "#32D583",
      500: "#12B76A",
      600: "#039855",
      700: "#027A48",
      800: "#05603A",
    },
    blg: {
      main: "#077A80",
      contrastText: "#fff",
      100: "#ECFEFF",
      200: "#CFFCFF",
      300: "#AFF0F4",
      400: "#84EBF0",
      500: "#4FDDE4",
      600: "#21C6CE",
      700: "#15ACB3",
      800: "#077A80",
    },
    ind: {
      main: "#6172F3",
      contrastText: "#fff",
      100: "#EEF4FF",
      200: "#C7D7FE",
      300: "#A4BCFD",
      400: "#8098F9",
      500: "#6172F3",
      600: "#444CE7",
      700: "#2D31A6",
      800: "#2D3282",
    },
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 768,
      xmd: 1080,
      lg: 1280,
      mlg: 1440,
      xl: 1920,
    },
  },

  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 700,
          borderRadius: 999,
          lineHeight: 1.1,
          padding: "1rem 1.5rem",
          minWidth: "112px",
          boxShadow: "none !important",
          textTransform: "capitalize",

          "&:hover": {
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
          },

          "@media (max-width: 768px)": {
            fontSize: "1rem",
            lineHeight: 1.5,
            padding: "0.75rem 1.25rem",
          },
        },
      },
      variants: [
        {
          props: { variant: "outlined" },
          style: {
            border: "1px solid",
            borderColor: "#D0D5DD",
            transition: "all 0.3s",
            color: "inherit",

            "&:hover": {
              borderColor: "#D0D5DD",
              backgroundColor: "#F2F4F7",
            },
          },
        },
      ],
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingLeft: "32px",
          paddingRight: "32px",

          "@media (min-width: 600px)": {
            paddingLeft: "32px",
            paddingRight: "32px",
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: 0,
          color: "#C4C4C4",
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          "hero-xl": "p",
          hero: "p",
          h1: "p",
          h2: "p",
          h3: "p",
          h4: "p",
          h5: "p",
          h6: "p",
          h7: "p",
          h8: "p",
          "body-2xl": "p",
          "body-xl": "p",
          "body-lg": "p",
          body: "p",
          "body-sm": "p",
        },
      },
    },
  },

  typography: {
    fontFamily: "var(--font-dm-sans), sans-serif",

    "hero-xl": {
      fontFamily: "var(--font-satoshi)",
      fontSize: "5.5rem",
      letterSpacing: "-0.02em",
      lineHeight: 1.1,
      fontWeight: 900,

      "@media (max-width: 896px)": {
        fontSize: "5rem",
      },

      "@media (max-width: 600px)": {
        fontSize: "4.5rem",
      },
    },

    hero: {
      fontFamily: "var(--font-satoshi)",
      fontSize: "4rem",
      letterSpacing: "-0.03em",
      lineHeight: 1.2,
      fontWeight: 900,

      "@media (max-width: 896px)": {
        fontSize: "3.5rem",
      },

      "@media (max-width: 600px)": {
        fontSize: "3rem",
      },
    },

    h1: {
      fontFamily: "var(--font-satoshi)",
      fontSize: "3.5rem",
      letterSpacing: "-0.01em",
      lineHeight: 1.25,
      fontWeight: 900,

      "@media (max-width: 896px)": {
        fontSize: "3rem",
      },

      "@media (max-width: 600px)": {
        fontSize: "2.5rem",
      },
    },

    h2: {
      fontFamily: "var(--font-satoshi)",
      fontSize: "3rem",
      lineHeight: 1.35,
      fontWeight: 900,

      "@media (max-width: 896px)": {
        fontSize: "2.5rem",
      },

      "@media (max-width: 600px)": {
        fontSize: "2rem",
      },
    },

    h3: {
      fontFamily: "var(--font-satoshi)",
      fontSize: "2.5rem",
      lineHeight: 1.4,

      "@media (max-width: 896px)": {
        fontSize: "2rem",
      },

      "@media (max-width: 600px)": {
        fontSize: "1.5rem",
      },
    },

    h4: {
      fontFamily: "var(--font-satoshi)",
      fontSize: "2.25rem",
      lineHeight: 1.4,

      "@media (max-width: 896px)": {
        fontSize: "2rem",
      },

      "@media (max-width: 600px)": {
        fontSize: "1.75rem",
      },
    },

    h5: {
      fontFamily: "var(--font-satoshi)",
      fontSize: "2rem",
      lineHeight: 1.4,

      "@media (max-width: 896px)": {
        fontSize: "1.75rem",
      },

      "@media (max-width: 600px)": {
        fontSize: "1.5rem",
      },
    },

    h6: {
      fontFamily: "var(--font-satoshi)",
      fontSize: "1.75rem",
      lineHeight: 1.5,

      "@media (max-width: 896px)": {
        fontSize: "1.5rem",
      },

      "@media (max-width: 600px)": {
        fontSize: "1.25rem",
      },
    },

    h7: {
      fontFamily: "var(--font-satoshi)",
      fontSize: "1.5rem",
      lineHeight: 1.5,
      fontWeight: 500,

      "@media (max-width: 896px)": {
        fontSize: "1.25rem",
      },

      "@media (max-width: 600px)": {
        fontSize: "1.125rem",
      },
    },

    h8: {
      fontFamily: "var(--font-satoshi)",
      fontSize: "1.25rem",
      lineHeight: 1.5,
      fontWeight: 500,

      "@media (max-width: 896px)": {
        fontSize: "1.125rem",
      },

      "@media (max-width: 600px)": {
        fontSize: "1rem",
      },
    },

    "body-2xl": {
      fontFamily: "var(--font-satoshi)",
      fontSize: "1.5rem",
      lineHeight: 1.5,
    },

    "body-xl": {
      fontFamily: "var(--font-satoshi)",
      fontSize: "1.25rem",
      lineHeight: 1.5,
      fontWeight: 500,

      "@media (max-width: 896px)": {
        fontSize: "1.125rem",
      },

      "@media (max-width: 600px)": {
        fontSize: "1rem",
      },
    },

    "body-lg": {
      fontFamily: "var(--font-satoshi)",
      fontSize: "1.125rem",
      lineHeight: 1.5,
      fontWeight: 500,

      "@media (max-width: 600px)": {
        fontSize: "1rem",
      },
    },

    body: {
      fontFamily: "var(--font-satoshi)",
      fontSize: "1rem",
      lineHeight: 1.5,
      fontWeight: 500,
    },

    "body-sm": {
      fontFamily: "var(--font-satoshi)",
      fontSize: "0.875rem",
      lineHeight: 1.5,
      fontWeight: 500,
    },
  },
});

export default theme;
