import { useState, useRef, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { IoArrowBackOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import {
  checkQuestionThread,
  getAAIToken,
  getDeepgramToken,
  saveChat,
} from "services/api";
import AAITranscriber from "services/aai-transcriber";
import DeepgramTranscriber from "services/deepgram-transcriber";
import WhiteHeaderSidebarLayout from "layouts/WhiteHeaderSidebarLayout";
import { ReactComponent as DoubleArrow } from "assets/icons/double-arrow.svg";
import { ReactComponent as SummarizeIcon } from "assets/icons/summarize.svg";
import Drawer from "components/Drawer";
import ChatBox from "components/ChatBoxV2";
import { AppContext } from "App";
import AllCases from "components/AllCases";
import styles from "./styles";
import Summary from "containers/Summary";
import {
  fetchAllBrains,
  selectCurrentBrain,
  setCurrentBrain
} from "store/slices/knowledgeBaseSlice";

export default function AssistantFlowV2() {
  const authToken = localStorage.getItem("authToken");
  const dispatch = useDispatch();

  const sessionMessages = useSelector((state) => state.chat.selectedSessionMessages);

  const selectedBrain = useSelector(selectCurrentBrain);

  const { notifyError } = useContext(AppContext);
  const navigate = useNavigate();

  const [processModel, setProcessModel] = useState("gpt");
  const [transcriptModel, setTranscriptModel] = useState("assembly-ai");

  const [isDrawerOpen, setIsDrawerOpen] = useState(true);
  const [chatHistory, setChatHistory] = useState([]);
  const [isAnswering, setIsAnswering] = useState(false);
  const [customer, setCustomer] = useState(null);
  const [transcript, setTranscript] = useState("");
  const [summary, setSummary] = useState("");
  const [timer, setTimer] = useState(0);
  const [state, setState] = useState("empty"); // empty, idle, halting, recording, stopping, view-summary
  const [selectedTab, setSelectedTab] = useState("transcript");

  const threadIdRef = useRef(null);
  const transcriberRef = useRef(null);
  const boxRef = useRef(null);

  const handleDrawerToggle = () => {
    setIsDrawerOpen((current) => !current);
  };

  const handleSummarizeClick = () => {
    setState("view-summary");
  };

  const handleSummaryBack = () => {
    setState("idle");
  };

  // Leaving this in for testing purposes.
  const doNothing = () => {
    console.warn("Skipping question");
  };

  const stopRecording = async (event) => {
    event.preventDefault();

    setState("stopping");
    await transcriberRef.current?.close();
    transcriberRef.current = null;

    const summary = await saveChat(
      transcript,
      threadIdRef.current,
      processModel
    ).catch((e) => notifyError(e.message, 4000));

    setSummary(summary);
    setState("idle");
  };

  const checkQuestion = async (message) => {
    const data = await checkQuestionThread(
      message,
      threadIdRef.current,
      processModel
    ).catch((e) => notifyError(e.message, 4000));

    if (data.questionDetected) {
      setIsAnswering(true);
      const { question } = data;
      setChatHistory((prev) => [...prev, { question, assistant: selectedBrain }]);
    }
  };

  const getTranscriber = async () => {
    if (transcriptModel === "assembly-ai") {
      const token = await getAAIToken().catch((e) =>
        notifyError(e.message, 4000)
      );
      transcriberRef.current = new AAITranscriber(token);
    } else if (transcriptModel === "deepgram") {
      const token = await getDeepgramToken().catch((e) =>
        notifyError(e.message, 4000)
      );
      transcriberRef.current = new DeepgramTranscriber(token);
    }
  };

  const startRecording = async () => {
    if (!authToken) {
      notifyError("Login is required to use this feature.", 4000);
      navigate("/login");
      return;
    }

    setState("halting");
    setTranscript("");

    const texts = {};
    await getTranscriber();

    if (!transcriberRef.current) {
      setState("idle");
      return;
    }

    setSelectedTab("transcript");

    transcriberRef.current.listen.ontranscript = async (message) => {
      setTranscript("");
      if (boxRef.current) {
        boxRef.current.scrollTop = boxRef.current.scrollHeight;
      }
      texts[message.start] = message.text;
      const keys = Object.keys(texts);
      keys.sort((a, b) => a - b);

      for (const key of keys) {
        if (texts[key]) {
          setTranscript((prev) => (prev += `${texts[key]}\n`));
        }
      }
    };

    transcriberRef.current.listen.onfinal = checkQuestion;

    await transcriberRef.current.getMicrophone().catch((e) => {
      if (e.name === "NotAllowedError") {
        notifyError("Microphone access is required to use this feature.", 4000);
      } else if (e.name === "NotFoundError") {
        notifyError("No microphone detected.", 4000);
      }
      setState("idle");
    });

    await transcriberRef.current
      .connect()
      .then(() => setState("recording"))
      .catch((e) => {
        notifyError(e.message, 4000);
        setState("idle");
      });
  };

  useEffect(() => {
    if (transcriberRef?.current?.listen) {
      if (isAnswering) {
        transcriberRef.current.listen.onfinal = doNothing;
      } else {
        transcriberRef.current.listen.onfinal = checkQuestion;
      }
    }
  }, [isAnswering]);

  // This useEffect updates the timer
  useEffect(() => {
    if (state === "empty") {
      return;
    }

    if (state === "idle") {
      setTimer(0);
      return;
    }

    const updateTimer = () => {
      setTimer(val => val + 1);
    }

    const timerInterval = setInterval(updateTimer, 1000);

    return () => clearInterval(timerInterval);
  }, [state])

  // Returns formatted time of the recording
  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600).toString().padStart(2, '0');
    const minutes = Math.floor((seconds % 3600) / 60).toString().padStart(2, '0');
    const secs = (seconds % 60).toString().padStart(2, '0');
    return `${hours}:${minutes}:${secs}`;
  };

  const handleAskManualQuestion = (question) => {
    setChatHistory((prev) => [...prev, { question, assistant: selectedBrain }]);
    setState("idle");
  };

  const handleReset = () => {
    setChatHistory([]);
    setSummary("");
    threadIdRef.current = null;
    setState("empty");
  };

  // On load, fetch all brains
  useEffect(() => {
    try {
      dispatch(fetchAllBrains());
    } catch (e) {
      notifyError(e.message, 4000);
    }
  }, []);

  // Update chat messages when session messages are fetched
  useEffect(() => {
    if (sessionMessages.length > 0) {
      setChatHistory(sessionMessages);
    }
  }, [sessionMessages]);

  return (
    <WhiteHeaderSidebarLayout
      // TODO: refactor next line
      handleReset={(state === "view-summary") ? undefined : (state === "idle" || chatHistory.length > 0) ? handleReset : undefined}
      leftDrawer={<AllCases />}
    >
      <Box
        sx={{
          width: isDrawerOpen ? "calc(100% - 500px)" : "100%",
          overflowX: "hidden",
          maxWidth: "100vw",
          height: "100%",
        }}
      >
        <Box sx={styles.topRow}>
          {state !== "view-summary" && (
            <>
              <Typography sx={styles.nameLabel}>
                {customer?.first_name || ""}
              </Typography>

              <Box sx={styles.timer}>
                <span style={styles.dot}></span>
                <Typography sx={styles.timerText}>
                  {formatTime(timer)}
                </Typography>
              </Box>

              <Box sx={styles.summarizeButtonContainer}>
                <Button
                  variant="outlined"
                  sx={styles.summarizeButton}
                  startIcon={<SummarizeIcon />}
                  onClick={handleSummarizeClick}
                >
                  Summarize
                </Button>
              </Box>
            </>
          )}

          {state === "view-summary" && (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton onClick={handleSummaryBack} sx={{ ml: "10px" }}>
                <IoArrowBackOutline />
              </IconButton>

              <Typography sx={styles.nameLabel}>
                Summary
              </Typography>
            </Box>
          )}

          <IconButton
            sx={{
              ...styles.toggleButton,
              transform: isDrawerOpen ? "rotate(0deg)" : "rotate(180deg)",
              ...(state === "view-summary" ? { ml : "auto" } : {}),
            }}
            onClick={handleDrawerToggle}
          >
            <DoubleArrow />
          </IconButton>
        </Box>


        <Box sx={{ display: "flex", flexDirection: "column", height: "calc(100% - 72px)" }}>
          {state === "view-summary" && (
            <Summary summary={summary} chatHistory={chatHistory} />
          )}

          {state !== "view-summary" && (
              <ChatBox
                chatHistory={chatHistory}
                setChatHistory={setChatHistory}
                processModel={processModel}
                threadIdRef={threadIdRef}
                setIsAnswering={setIsAnswering}
                customer={customer}
                state={state}
                startRecording={startRecording}
                stopRecording={stopRecording}
                handleAskManualQuestion={handleAskManualQuestion}
              />
          )}

          <Drawer
            open={isDrawerOpen}
            customer={customer}
            setCustomer={setCustomer}
            state={state}
            transcript={transcript}
            setTranscript={setTranscript}
            boxRef={boxRef}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />
        </Box>
      </Box>

    </WhiteHeaderSidebarLayout>
  );
}
