const styles = {
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  toggleButton: {
    border: "1px solid #E3E6EA",
    mt: "10px",
    mb: "20px",
    width: "40px",
  },

  navButton: {
    width: "40px",
    height: "40px",
    my: "10px",
    borderRadius: "8px",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",

    "&:hover": {
      background: "#F5F4FF",
    },
  },
  selectedButton: {
    background: "#F5F4FF",
  },
};

export default styles;
