import { RealtimeTranscriber } from "assemblyai/streaming";
import * as RecordRTC from "recordrtc";

export default class AAITranscriber {
  #microphone = null;
  #stream = null;
  #token = null;
  #connection = null;
  #slidingWindow = [];

  constructor(token) {
    this.#token = token;
  }

  async getMicrophone() {
    this.#stream = await navigator.mediaDevices.getUserMedia({
      audio: true,
    });

    this.#microphone = new RecordRTC(this.#stream, {
      type: "audio",
      mimeType: "audio/webm;codecs=pcm",
      recorderType: RecordRTC.StereoAudioRecorder,
      timeSlice: 250,
      desiredSampRate: 16000,
      numberOfAudioChannels: 1,
      bufferSize: 4096,
      audioBitsPerSecond: 128000,
      ondataavailable: async (blob) => {
        if (!this.#connection) return;
        const buffer = await blob.arrayBuffer();
        this.#connection.sendAudio(buffer);
      },
    });
  }

  async connect() {
    if (!this.#microphone) await this.getMicrophone();
    this.#connection = new RealtimeTranscriber({
      token: this.#token,
      sampleRate: 16000,
    });
    await this.#connection.connect();
    this.#microphone.startRecording();
    console.log("Recording started");

    this.#connection.on("transcript", ({ text, audio_start }) => {
      this.listen.ontranscript({ text, start: audio_start });
    });

    this.#connection.on("transcript.final", async (message) => {
      const now = Math.floor(message.audio_start / 1000);
      this.#slidingWindow.push({
        text: `${message.text}\n`,
        recordedSince: now,
      });
      // Retain at least 5 sentences in the sliding window
      // and remove any sentences older than 60 seconds
      this.#slidingWindow = this.#slidingWindow.filter(
        (item, id) =>
          now - item.recordedSince < 30 || id >= this.#slidingWindow.length - 5
      );
      console.log("slidingWindow:", this.#slidingWindow);

      this.listen.onfinal(
        this.#slidingWindow.map((item) => item.text).join("")
      );
    });
  }

  listen = {
    ontranscript: (_message) => {},
    onfinal: (_message) => {},
  };

  async close() {
    this.#microphone.stopRecording();
    this.#stream.getTracks().forEach((track) => track.stop());
    await this.#connection.close();
  }
}
