import { createClient, LiveTranscriptionEvents } from "@deepgram/sdk";

export default class DeepgramTranscriber {
  #microphone = null;
  #token = null;
  #slidingWindow = [];

  constructor(token) {
    this.#token = token;
  }

  async getMicrophone() {
    const microphone = await navigator.mediaDevices.getUserMedia({
      audio: true,
    });
    this.#microphone = new MediaRecorder(microphone);
  }

  #startMicrophone(socket) {
    this.#microphone.onstart = () => {
      console.log("Recording started");
    };
    this.#microphone.onstop = () => {
      console.log("Recording stopped");
    };
    this.#microphone.ondataavailable = ({ data }) => {
      socket.send(data);
    };
    this.#microphone.start(500);
  }

  async connect() {
    if (!this.#microphone) await this.getMicrophone();
    const client = createClient(this.#token);
    const socket = client.listen.live({
      model: "nova-2",
      language: "en-US",
      smart_format: true,
      interim_results: true, // Set to true to receive partial results
    });

    socket.on(LiveTranscriptionEvents.Open, () => {
      this.#startMicrophone(socket);

      socket.on(LiveTranscriptionEvents.Transcript, async (message) => {
        const text = message.channel.alternatives[0].transcript;

        this.listen.ontranscript({ text, start: message.start * 1000 });

        if (message.is_final && text !== "") {
          const now = Math.floor(message.start);
          this.#slidingWindow.push({
            text: `${text}\n`,
            recordedSince: now,
          });
          // Retain at least 5 sentences in the sliding window
          // and remove any sentences older than 60 seconds
          this.#slidingWindow = this.#slidingWindow.filter(
            (item, id) =>
              now - item.recordedSince < 30 ||
              id >= this.#slidingWindow.length - 5
          );
          console.log("slidingWindow:", this.#slidingWindow);

          this.listen.onfinal(
            this.#slidingWindow.map((item) => item.text).join("")
          );
        }
      });
    });
  }

  listen = {
    ontranscript: (_message) => {},
    onfinal: (_message) => {},
  };

  async close() {
    this.#microphone.stop();
  }
}
