import { TextareaAutosize as BaseTextareaAutosize } from "@mui/base/TextareaAutosize";
import { LoadingButton } from "@mui/lab";
import { Box, styled } from "@mui/material";
import { useState } from "react";
import { IoArrowForward } from "react-icons/io5";

const TextareaAutosize = styled(BaseTextareaAutosize)({
  borderRadius: "24px",
  minHeight: "8px",
  border: "1px solid #D0D5DD",
  resize: "none",
  fontFamily: "Satoshi, sans-serif",
  fontSize: "20px",
  padding: "10px 14px",
  paddingBottom: "10px",
  fontWeight: 500,
  outline: "none",
  width: "100%",

  "&::placeholder": {
    color: "#D0D5DD",
  },
});

export default function QuestionInput({ askManualQuestion }) {
  const [loading, setLoading] = useState(false);
  const [question, setQuestion] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const message = question.trim();
    if (!message) return;

    setLoading(true);
    askManualQuestion(message);
    setLoading(false);
    setQuestion("");
  };

  const handleInputChange = (e) => {
    setQuestion(e.target.value);
  };

  // In the text area,
  // pressing enter asks the question.
  // Pressing shift+enter adds a new line.
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (event.shiftKey) {
        event.preventDefault();
        setQuestion(q => q + "\n");
      } else {
        handleSubmit(event);
      }
    }
  };

  return (
    <Box component="form" sx={{ position: "relative" }} onSubmit={handleSubmit}>
      <TextareaAutosize
        placeholder="Ask questions in this field"
        value={question}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
      />

      <LoadingButton
        variant="contained"
        type="submit"
        loading={loading}
        sx={{
          minWidth: 0,
          padding: "14px",
          position: "absolute",
          bottom: "8px",
          right: "5px",
        }}
      >
        <IoArrowForward size={15} />
      </LoadingButton>
    </Box>
  );
}
