import { Box, Typography } from "@mui/material";
import EmptyState from "components/EmptyState";
import Response from "components/Response";
import styles from "./styles";

export default function ChatBox({
  chatHistory,
  setChatHistory,
  processModel,
  threadIdRef,
  setIsAnswering,
  customer,
}) {
  return (
    <Box sx={styles.root}>
      <Typography fontSize="24px" fontWeight={700}>
        Response
      </Typography>

      <Box sx={styles.chatBox}>
        {chatHistory.length === 0 ? (
          <EmptyState text="No Response Yet" />
        ) : (
          chatHistory.map((c, index) => (
            <Response
              key={index}
              index={index}
              setChatHistory={setChatHistory}
              processModel={processModel}
              isLast={index === chatHistory.length - 1}
              threadIdRef={threadIdRef}
              setIsAnswering={setIsAnswering}
              customer={customer}
              {...c}
            />
          ))
        )}
      </Box>
    </Box>
  );
}
