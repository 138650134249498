import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import EmptyState from "components/EmptyState";
import { useEffect } from "react";
import styles from "./styles";

export default function LiveTranscription({
  state,
  transcript,
  setTranscript,
  boxRef,
}) {

  let message = "";
  switch (state) {
    case "halting":
      message = "Connecting...";
      break;
    case "recording":
      message = "Recording...";
      break;
    case "stopping":
      message = "Closing connection...";
      break;
    default:
      message = "Start speaking to transcribe";
  }

  useEffect(() => {
    if (state === "empty") {
      setTranscript("");
    }
  }, [state]);

  return (
    <Box sx={styles.root}>
      <Box>
        <Typography sx={styles.title}>
          Transcript
        </Typography>
      </Box>

      <Box sx={styles.transcriptBox} ref={boxRef}>
        {!transcript ? (
          <EmptyState text={message} />
        ) : (
          <Typography sx={styles.transcriptText}>
            {transcript}
          </Typography>
        )}
      </Box>
    </Box>
  );
}
