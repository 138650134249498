import { Box, Container, Divider, IconButton } from "@mui/material";
import Header from "components/Header";
import LiveTranscription from "components/LiveTranscription";
import ChatBox from "components/ChatBox";
import { useContext, useEffect, useRef, useState } from "react";
import HeaderSelect from "components/HeaderSelect";
import { TbReload } from "react-icons/tb";
import styles from "./styles";
import Summary from "containers/Summary";
import { IoClose } from "react-icons/io5";
import UserInfoDrawer from "components/UserInfoDrawer";
import { ReactComponent as DoubleArrow } from "assets/icons/double-arrow.svg";
import { fetchBrains } from "services/api";
import { AppContext } from "App";

export default function AssistantFlow() {
  const [chatHistory, setChatHistory] = useState([]);
  const [assistant, setAssistant] = useState("default");
  const [processModel, setProcessModel] = useState("gpt");
  const [state, setState] = useState("empty"); // empty, idle, halting, recording, stopping, view-summary
  const [summary, setSummary] = useState("");
  const [openDrawer, setOpenDrawer] = useState(true);
  const [isAnswering, setIsAnswering] = useState(false);
  const [customer, setCustomer] = useState(null);
  const [assistants, setAssistants] = useState([
    { id: "default", name: "Default" },
  ]);

  const threadIdRef = useRef(null);
  const isProd = process.env.REACT_APP_ENV === "production";
  const { notifyError } = useContext(AppContext);

  const handleSelectAsst = (id) => {
    if (id !== assistant) setAssistant(id);
  };

  const onHeaderBtnClick = () => {
    if (state === "view-summary") {
      setState("idle");
    } else {
      setChatHistory([]);
      setSummary("");
      threadIdRef.current = null;
      setState("empty");
    }
  };

  useEffect(() => {
    const getBrains = async () => {
      const otherBrains = await fetchBrains().catch((e) =>
        notifyError(e.message, 4000)
      );

      setAssistants([{ id: "default", name: "Default" }, ...otherBrains]);
    };

    getBrains();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Header processModel={processModel} setProcessModel={setProcessModel}>
        {state !== "view-summary" && (
          <HeaderSelect
            value={assistant}
            assistants={assistants}
            onSelect={handleSelectAsst}
            sx={{ display: { sm: "flex", xs: "none" } }}
          />
        )}

        {state === "view-summary" ? (
          <IconButton
            sx={styles.iconButton}
            onClick={onHeaderBtnClick}
          >
            <IoClose size="20px" />
          </IconButton>
        ) : (
          <IconButton
            sx={styles.iconButton}
            disabled={!summary || state !== "idle"}
            onClick={onHeaderBtnClick}
          >
            <TbReload size="20px" />
          </IconButton>
        )}
      </Header>

      {state === "view-summary" ? (
        <Summary summary={summary} chatHistory={chatHistory} />
      ) : (
        <Box sx={styles.root}>
          <Box
            sx={{
              ...styles.mainScrollable,
              width: openDrawer && !isProd ? "calc(100% - 360px)" : "100%",
            }}
          >
            <Container maxWidth="mlg" sx={styles.main}>
              <HeaderSelect
                value={assistant}
                assistants={assistants}
                onSelect={handleSelectAsst}
                sx={styles.mobileSelect}
              />

              <LiveTranscription
                assistant={assistant}
                setChatHistory={setChatHistory}
                processModel={processModel}
                state={state}
                setState={setState}
                summaryDisabled={!summary || state !== "idle"}
                setSummary={setSummary}
                threadIdRef={threadIdRef}
                isAnswering={isAnswering}
                setIsAnswering={setIsAnswering}
              />

              <Divider
                orientation="horizontal"
                flexItem
                sx={{ display: { md: "none", xs: "block" } }}
              />

              <ChatBox
                chatHistory={chatHistory}
                setChatHistory={setChatHistory}
                processModel={processModel}
                threadIdRef={threadIdRef}
                setIsAnswering={setIsAnswering}
                customer={customer}
              />
            </Container>

            {!isProd && (
              <IconButton
                onClick={() => setOpenDrawer(!openDrawer)}
                sx={{
                  ...styles.openDrawerButton,
                  transform: openDrawer ? "rotate(0deg)" : "rotate(180deg)",
                }}
              >
                <DoubleArrow />
              </IconButton>
            )}
          </Box>

          {!isProd && <UserInfoDrawer
              open={openDrawer}
              user={customer}
              setUser={setCustomer}
          />}
        </Box>
      )}
    </>
  );
}
