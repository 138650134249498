import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { IoPersonCircle } from "react-icons/io5";
import { TbReload } from "react-icons/tb";
import deciphrBlueLogo from "assets/images/deciphr-blue.svg";
import cxLogo from "assets/images/cx.svg";
import styles from "./styles";

export default function TopNavBar({ handleReset }) {
  return (
    <Box sx={styles.root}>
      <img src={deciphrBlueLogo} alt="Deciphr logo" height={40} />
      <img src={cxLogo} alt="CX logo" width={30} />

      {handleReset && (
        <IconButton onClick={handleReset} sx={styles.resetButton}>
          <TbReload size="20px" color="#10181E" />
        </IconButton>
      )}

      <IconButton sx={{ ...styles.userButton, ...(!handleReset ? { ml: "auto" } : {}) }}>
        <IoPersonCircle size={36} />
      </IconButton>
    </Box>
  );
}
