import { Box, Typography } from "@mui/material";

const info = [
  {
    type: "Date & Time",
    value: new Date().toLocaleString(),
  },
  {
    type: "Customer Name",
    value: "James Nguyen",
  },
  {
    type: "Agent Name",
    value: "John Doe",
  },
  {
    type: "Call Duration",
    value: "5 minutes",
  },
];

export default function SummaryInfo() {
  return (
    <div>
      <Box sx={{ display: "flex", gap: "28px", mb: "32px" }}>
        {info.map((i, index) => (
          <Box
            key={index}
            sx={{
              flex: 1,
              border: "1px solid #E3E6EA",
              bgcolor: "primary.100",
              padding: "12px",
              borderRadius: "12px",
            }}
          >
            <Typography fontWeight={700} sx={{ mb: "16px" }}>
              {i.type}:
            </Typography>

            <Typography color="gray.600">{i.value}</Typography>
          </Box>
        ))}
      </Box>

      <Box
        sx={{
          border: "1px solid #E3E6EA",
          bgcolor: "primary.100",
          borderRadius: "12px",
          padding: "12px",
        }}
      >
        <Typography fontWeight={700} sx={{ mb: "16px" }}>
          Call Outcome:
        </Typography>

        <Typography color="gray.600">
          Customer was very appreciative of the detailed explanations and felt
          well-supported.
        </Typography>
      </Box>
    </div>
  );
}
