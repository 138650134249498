import { Box } from "@mui/material";
import InfoSearchInput from "components/InfoSearchInput";
import UserDetails from "components/UserDetails";
import UserList from "components/UserList";
import { useEffect, useState } from "react";

export default function UserInfo({ history, setHistory, user, setUser }) {
  const [users, setUsers] = useState(null);
  const current = history[history.length - 1];

  useEffect(() => {
    if (users) {
      if (users.length === 1) {
        setUser(users[0]);
        setHistory((prev) => [...prev, "show-info"]);
      } else {
        setHistory((prev) => [...prev, "select"]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);

  return (
    <Box sx={{ padding: "24px" }}>
      {current === "search" ? (
        <InfoSearchInput setUsers={setUsers} />
      ) : current === "select" ? (
        <UserList users={users} setUser={setUser} setHistory={setHistory} />
      ) : current === "show-info" ? (
        <UserDetails user={user} />
      ) : (
        null``
      )}
    </Box>
  );
}
