const styles = {
  root: {
    display: "flex",
    flexDirection: "column",
    gap: "32px",
    flex: "1 1 45%",
  },

  transcriptBox: {
    padding: "36px",
    borderRadius: "24px",
    border: "1px solid",
    borderColor: "gray.300",
    height: "400px",
    width: "100%",
    overflowY: "auto",
    flexShrink: 0,
  },

  selectTrigger: {
    display: "flex",
    alignItems: "center",
    gap: "12px",
    cursor: "pointer",
    "& svg": { color: "gray.400" },
    width: "max-content",
  },

  recordButton: {
    fontSize: "18px",
    mx: "auto",
    minWidth: "160px",
    py: "20px",
  },

  stopIcon: {
    height: "18px",
    width: "18px",
    borderRadius: "4px",
    bgcolor: "#fff",
  },

  summaryButtonBox: {
    flex: 1,
    display: "flex",
    alignItems: "end",
    justifyContent: "center",
  },

  summaryButton: {
    justifySelf: "end",
    fontSize: "16px",
    padding: "12px 16px",
    color: "gray.600",
  },
};

export default styles;
