import { LoadingButton } from "@mui/lab";
import { Box, InputBase, Typography } from "@mui/material";
import { AppContext } from "App";
import { useContext, useState } from "react";
import { searchUsers } from "services/api";

export default function InfoSearchInput({ setUsers }) {
  const [loading, setLoading] = useState(false);
  const { notifyError } = useContext(AppContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const email = e.target.email.value;

    if (!email) {
      notifyError("Please enter an email");
      setLoading(false);
      return;
    }

    const users = await searchUsers(email).catch((e) =>
      notifyError(e.message, 4000)
    );
    setLoading(false);
    setUsers(users);
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        bgcolor: "#fff",
        border: "1px solid #E3E6EA",
        borderRadius: "16px",
        padding: "24px",
      }}
    >
      <Typography component="label" variant="body" htmlFor="customer-email">
        Customer Email
      </Typography>

      <InputBase
        id="customer-email"
        name="email"
        placeholder="Enter Email"
        sx={{
          mt: "16px",
          mb: "32px",
          border: "1px solid #E3E6EA",
          borderRadius: "12px",
          padding: "12px 16px",
          width: "100%",
        }}
      />

      <Box sx={{ display: "flex", justifyContent: "end" }}>
        <LoadingButton
          variant="contained"
          loading={loading}
          type="submit"
          sx={{ py: "12px" }}
        >
          Get Details
        </LoadingButton>
      </Box>
    </Box>
  );
}
