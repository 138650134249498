import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import TextField from "@mui/material/TextField";
import { useDispatch, useSelector } from "react-redux";
import { IoChevronDown } from "react-icons/io5";
import { useState } from "react";
import { selectCurrentBrain, selectAllBrains, setCurrentBrain } from "store/slices/knowledgeBaseSlice";
import styles from "./styles";

const dummyData = [
  {
    id: 1,
    questionText: "What are the top trending collaborating interface design right now?",
  },
  {
    id: 2,
    questionText: "What are the top trending collaborating interface design right now?",
  },
  {
    id: 3,
    questionText: "What are the top trending collaborating interface design right now?",
  },
  {
    id: 4,
    questionText: "What are the top trending collaborating interface design right now?",
  },
];

export default function KnowledgeBaseSidebar() {
  const dispatch = useDispatch();

  const selectedBrain = useSelector(selectCurrentBrain);
  const allBrains = useSelector(selectAllBrains);

  const [expandedQuestion, setExpandedQuestion] = useState(null);

  const handleExpandToggle = (panel) => (_, newExpanded) => {
    setExpandedQuestion(newExpanded ? panel : false);
  };

  const handleSelectBrain = (event) => {
    const id = event.target.value;
    if (id !== selectedBrain) {
      dispatch(setCurrentBrain(id));
    }
  };

  return (
    <>
      <Typography sx={{ ...styles.title, mb: "30px" }}>
        Knowledge Base
      </Typography>

      <FormControl fullWidth>
        <Select
          value={selectedBrain}
          onChange={handleSelectBrain}
          sx={styles.selectBrainDropdown}
        >
          {allBrains.map((brain) => (
            <MenuItem
              key={brain.id}
              value={brain.id}
            >
              {brain.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/*
      <Box sx={styles.accordionContainer}>
        {dummyData.map(question => (
          <Accordion
            sx={styles.accordion}
            expanded={expandedQuestion === question.id}
            onChange={handleExpandToggle(question.id)}
          >
            <AccordionSummary
              sx={styles.accordionSummary}
              expandIcon={<IoChevronDown color="#6552F3" />}
            >
              {question.questionText}
            </AccordionSummary>

            <AccordionDetails>
              <Box sx={styles.inputContainer}>
                <TextField
                  sx={styles.inputField}
                  multiline
                  placeholder="Type your answer..."
                />
              </Box>

              <Button
                sx={styles.submitButton}
                variant="contained"
              >
                Submit for Review
              </Button>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
      */}
    </>
  );
}
