import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { IoIosArrowDown } from "react-icons/io";
import Markdown from "react-markdown";

export default function FrequentQuestion({ answer, question, ariaControls }) {
  return (
    <Accordion
      sx={{
        boxShadow: "none !important",
        py: "24px",
        width: "100%",
        backgroundColor: "transparent",
        borderRadius: "0 !important",
        color: "inherit !important",

        "&.Mui-expanded": { margin: 0 },
        "&::before": { display: "none" },
      }}
    >
      <AccordionSummary
        expandIcon={<IoIosArrowDown size="20px" />}
        aria-controls={ariaControls}
        sx={{
          padding: 0,
          minHeight: "auto",
          fontWeight: 700,
          fontSize: "18px",

          "& .MuiAccordionSummary-content": {
            margin: 0,
            alignItems: "center",
            gap: "16px",
            "&.Mui-expanded": { margin: 0 },
          },

          "&.Mui-expanded": { minHeight: 0 },

          "& svg": { color: "gray.400" },
        }}
      >
        {question}
      </AccordionSummary>

      <AccordionDetails sx={{ padding: 0, mt: "1rem", color: "gray.500" }}>
        <Markdown>{answer}</Markdown>
      </AccordionDetails>
    </Accordion>
  );
}
