import { ReactComponent as PhoneIcon } from "assets/icons/phone.svg";
import { ReactComponent as AgeIcon } from "assets/icons/age.svg";
import { ReactComponent as GenderIcon } from "assets/icons/gender.svg";
import { ReactComponent as SubscriptionIcon } from "assets/icons/subscription.svg";
import { ReactComponent as LocationIcon } from "assets/icons/location.svg";
import { ReactComponent as MapIcon } from "assets/icons/map.svg";
import { ReactComponent as StateIcon } from "assets/icons/state.svg";
import { ReactComponent as GlobeIcon } from "assets/icons/globe.svg";

export const iconMapper = {
  phone_number: PhoneIcon,
  age: AgeIcon,
  gender: GenderIcon,
  subscription_plan: SubscriptionIcon,
  address: LocationIcon,
  city: MapIcon,
  state: StateIcon,
  country: GlobeIcon,
};

export const order = [
  ["phone_number", "age", "gender"],
  ["subscription_plan"],
  ["address", "city", "state", "country"],
];
