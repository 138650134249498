import { Box, Typography, capitalize } from "@mui/material";
import { order, iconMapper } from "./data";

const renderIcon = (key) => {
  const Icon = iconMapper[key];
  return (
    <Box sx={{ color: "gray.500", fontSize: "20px", my: "2px" }}>
      <Icon />
    </Box>
  );
};

export default function UserDetails({ user }) {
  return order.map((group, idx) => (
    <Box
      key={group[0]}
      sx={{
        bgcolor: "#fff",
        borderRadius: "16px",
        border: "1px solid #E3E6EA",
        marginBottom: idx === order.length - 1 ? 0 : "12px",
      }}
    >
      {idx === 0 && (
        <Box sx={{ padding: "24px", borderBottom: "1px solid #E3E6EA" }}>
          <Typography variant="h8" fontWeight={700} sx={{ mb: "8px" }}>
            {user.first_name} {user.last_name}
          </Typography>

          <Typography variant="body-lg" color="gray.600">
            {user.email}
          </Typography>
        </Box>
      )}

      <Box
        sx={{
          "& > *:not(:last-child)": { marginBottom: "24px" },
          padding: "24px",
        }}
      >
        {group.map((key) => (
          <Box sx={{ display: "flex", gap: "24px" }}>
            {renderIcon(key)}

            <div>
              <Typography variant="body" color="gray.500" sx={{ mb: "12px" }}>
                {capitalize(key)}
              </Typography>

              <Typography variant="body">{user[key]}</Typography>
            </div>
          </Box>
        ))}
      </Box>
    </Box>
  ));
}
