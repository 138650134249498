import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getSessionMessages, getSessions } from "services/api";

const initialState = {
  sessions: [],
  selectedSession: null,
  selectedSessionMessages: [],
};

export const fetchSessions = createAsyncThunk(
  "chats/fetchSessions",
  async () => {
    const response = await getSessions();
    return response.sessions;
  }
);

export const fetchSessionMessages = createAsyncThunk(
  "chats/fetchSessionMessages",
  async (threadId) => {
    const response = await getSessionMessages(threadId);
    return response;
  }
);

export const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    setSelectedSession: (state, action) => {
      state.selectedSession = action.payload;
      state.selectedSessionMessages = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSessions.fulfilled, (state, action) => {
      state.sessions = action.payload;
    });

    builder.addCase(fetchSessionMessages.fulfilled, (state, action) => {
      const messages = [];
      let currentQuestion = null;
      action.payload.messages.forEach((message) => {
        if (message.role === "user") {
          currentQuestion = message.content;
        } else if (message.role === "assistant" && currentQuestion !== null) {
          messages.push({
            question: currentQuestion,
            answer: message.content,
          });
          currentQuestion = null;
        }
      });
      state.selectedSessionMessages = messages;
    });
  },
});

export const { setSelectedSession } = chatSlice.actions;

export default chatSlice.reducer;
