import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import { ReactComponent as DoubleArrow } from "assets/icons/double-arrow.svg";
import { ReactComponent as HomeIcon } from "assets/icons/home.svg";
import { ReactComponent as PeopleBlueIcon } from "assets/icons/people-blue.svg";
import styles from "./styles";

export default function SideNavBar({ expanded, onExpandToggle }) {
  return (
    <Box sx={styles.root}>
      {/*
      <IconButton
        sx={{
          ...styles.toggleButton,
          transform: expanded ? "rotate(180deg)" : "rotate(0deg)",
        }}
        onClick={onExpandToggle}
      >
        <DoubleArrow />
      </IconButton>
      */}

      <Box sx={{ ...styles.toggleButton, ...styles.navButton, ...styles.selectedButton }}>
        <HomeIcon
          style={{ marginLeft: "auto", marginRight: "auto" }}
          width={24}
          height={24}
        />
      </Box>

      {/*
      <Box sx={{ ...styles.navButton, ...styles.selectedButton }}>
        <PeopleBlueIcon
          style={{ marginLeft: "auto", marginRight: "auto" }}
          width={24}
          height={24}
        />
      </Box>
      */}
    </Box>
  );
}
