const styles = {
  root: {
    py: "32px",
    borderBottom: "1px solid",
    borderColor: "gray.300",
    width: "100%",
  },

  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  logoBox: {
    height: { md: "48px", xs: "40px" },
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    gap: "12px",
    color: "gray.400",
  },
};

export default styles;
