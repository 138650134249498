import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Container } from "@mui/material";
import styles from "./styles";
import { IoIosArrowDown } from "react-icons/io";
import CustomSelect from "components/CustomSelect";

const models = [
  { id: "gpt", name: "GPT-4o" },
  { id: "groq", name: "Groq" },
];

export default function Header({ children, processModel = "gpt", setProcessModel }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const authToken = localStorage.getItem("authToken");

  const onLogoClick = (e) => {
    if (!authToken) {
      navigate("/");
    } else {
      setAnchorEl(e.currentTarget);
    }
  };

  const handleModelSelect = ({ id }) => {
    setProcessModel(id);
  }

  return (
    <Box sx={styles.root}>
      <Container maxWidth="mlg" sx={styles.container}>

        <Box sx={styles.logoBox} onClick={onLogoClick}>
          {process.env.REACT_APP_ENV === "production" && (
            <img
              src="/assets/images/logo.svg"
              alt="Deciphr Assistant Logo"
              height="100%"
            />
          )}

          {authToken && <IoIosArrowDown size="1.25em" />}
        </Box>

        <CustomSelect
          value={processModel}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          options={models}
          onSelect={handleModelSelect}
          onClose={() => setAnchorEl(null)}
        />

        {children}
      </Container>
    </Box>
  );
}
