import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchBrains } from "services/api";

const initialState = {
  currentBrain: "default",
  allBrains: [{ id: "default", name: "Default" }],
};

export const knowledgeBaseSlice = createSlice({
  name: "knowledgeBase",
  initialState,
  reducers: {
    setCurrentBrain: (state, action) => {
      state.currentBrain = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllBrains.fulfilled, (state, action) => {
      console.log("got:", action.payload);
      state.allBrains = [
        { id: "default", name: "Default" },
        ...action.payload,
      ];
    });
  },
});

export const selectCurrentBrain = (state) => state.knowledgeBase.currentBrain;
export const selectAllBrains = (state) => state.knowledgeBase.allBrains;

export const { setCurrentBrain } = knowledgeBaseSlice.actions;

export const fetchAllBrains = createAsyncThunk(
  "knowledgeBase/fetchAllBrains",
  async () => {
    const response = await fetchBrains();
    return response;
  }
);

export default knowledgeBaseSlice.reducer;
