import Axios from "axios";

const axios = Axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
});

axios.interceptors.request.use(
  (config) => {
    const authToken = localStorage.getItem("authToken");

    if (authToken) {
      config.headers.set("Authorization", `Bearer ${authToken}`);
    }

    return config;
  },
  (error) => Promise.reject(error),
);

export default axios;
