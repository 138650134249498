import { useEffect } from "react";
import Avatar from "@mui/material/Avatar"; import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
// import { IoCloseOutline } from "react-icons/io5";
import { fetchSessionMessages, fetchSessions, setSelectedSession } from "store/slices/chatSlice";

export default function AllCases() {
  const dispatch = useDispatch();
  const allSessions = useSelector((state) => state.chat.sessions);
  const selectedSession = useSelector((state) => state.chat.selectedSession);

  const handleClick = (session) => {
    dispatch(setSelectedSession(session));
    dispatch(fetchSessionMessages(session.thread_id));
  };

  useEffect(() => {
    dispatch(fetchSessions());
  }, []);

  return (
    <Box>
      <Box sx={styles.headingContainer}>
        <Typography sx={styles.heading}>
          All Cases
        </Typography>
      {/*<Box sx={styles.closeButton}>
          <IoCloseOutline color="#000" size={24} />
        </Box>*/}
      </Box>
      <Box>
        {allSessions.map((sess) => {
          const displayName = sess.customer ? sess.customer.first_name : sess.chat_id;
          const avatarLetter = displayName.slice(0, 1);

          const date = new Date(parseInt(sess.updated_at, 10));
          const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
          const day = weekdays[date.getDay()];
          const time = date.toLocaleTimeString(
            'en-US',
            { hour: '2-digit', minute: '2-digit', hour12: false },
          );
          const displayTime = `${day} ${time}`;

          const isSelected = sess.thread_id === selectedSession?.thread_id;

          return (
            <Box
              sx={{
                ...styles.caseContainer,
                ...(isSelected ? styles.selectedCase : {}),
              }}
              onClick={() => handleClick(sess)}
              key={sess.thread_id}
            >
              <Avatar sx={styles.avatar} alt={displayName}>
                {avatarLetter}
              </Avatar>
              <Typography sx={styles.displayName}>
                {displayName}
              </Typography>
              <Typography sx={styles.timestamp}>
                {displayTime}
              </Typography>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}

const styles = {
  headingContainer: {
    height: "64px",
    borderBottom: "1px solid #E3E6EA",
    display: "flex",
    alignItems: "center",
    px: "20px",
  },

  heading: {
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "23px",
  },

  caseContainer: {
    display: "flex",
    height: "90px",
    alignItems: "center",
    borderBottom: "1px solid #E3E6EA",
    px: "10px",
    cursor: "pointer",

    "&:hover": {
      backgroundColor:"#F6F7F9",
    },
  },

  selectedCase: {
    borderLeft: "2px solid #6552F3",
  },

  displayName: {
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "23px",
  },

  avatar: {
    width: "24px",
    height: "24px",
    mr: "10px",
  },

  timestamp: {
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "20px",
    color: "#98A2B3",
    ml: "auto",
  },

  closeButton: {
    ml: "auto",
    cursor: "pointer",
  },
};
