import { Box, IconButton, InputBase, Typography } from "@mui/material";
import { useState } from "react";
import { HiOutlineMail } from "react-icons/hi";
import { MdOutlineVisibility } from "react-icons/md";
import { MdOutlineVisibilityOff } from "react-icons/md";

/**
 * @typedef {object} Props
 *
 * @property {string} id
 * @property {string} label
 * @property {string} type
 * @property {boolean} required
 * @property {boolean} disabled
 * @property {string} placeholder
 * @property {React.ReactNode} endAdorement
 * @property {import("formik").UseFormikProps} formik
 *
 * @param {Props} props
 */
export default function FormInput({
  id,
  label,
  type,
  required,
  disabled,
  placeholder,
  formik,
}) {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "8px",
      }}
    >
      <Typography
        component="label"
        fontSize={{ sm: "14px", xs: "12px" }}
        fontWeight={700}
        htmlFor={id}
        sx={{
          fontFamily: "var(--font-satoshi)",
          color: "#101928",
          width: "max-content",
          textTransform: "capitalize",
        }}
      >
        {label}
      </Typography>

      <InputBase
        id={id}
        type={type === "password" && showPassword ? "text" : type}
        required={required}
        disabled={disabled}
        placeholder={placeholder}
        value={formik.values[id]}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        onFocus={() => formik.setFieldTouched(id, false)}
        endAdornment={
          <Box
            sx={{ color: disabled ? "gray.300" : "gray.500", fontSize: "24px" }}
          >
            {id === "email" ? (
              <HiOutlineMail />
            ) : type === "password" ? (
              <IconButton
                sx={{ padding: 0, color: "inherit" }}
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <MdOutlineVisibility />
                ) : (
                  <MdOutlineVisibilityOff />
                )}
              </IconButton>
            ) : null}
          </Box>
        }
        sx={{
          boxShadow: `inset 0 0 0 1.5px ${
            formik.touched[id] && formik.errors[id] ? "#F04438" : "#D0D5DD"
          }`,
          borderRadius: "12px",
          padding: "16px",
          fontSize: { sm: "14px", xs: "12px" },
          fontWeight: 500,
          transition: "box-shadow 0.15s ease-in-out",
          fontFamily: "var(--font-satoshi)",
          "&.Mui-focused": {
            boxShadow: "inset 0 0 0 1.5px #6552F3",
          },
        }}
      />

      <Typography
        variant="body-sm"
        sx={{
          color: "#F04438",
          textAlign: "start",
          height: "1.2em",
        }}
      >
        {formik.touched[id] && formik.errors[id]}
      </Typography>
    </Box>
  );
}
