import { Box, Button, Divider, Typography } from "@mui/material";
import styles from "./styles";
import { PiMicrophoneBold } from "react-icons/pi";
import EmptyState from "components/EmptyState";
import { useContext, useEffect, useRef, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { AppContext } from "App";
import {
  checkQuestionThread,
  getAAIToken,
  getDeepgramToken,
  saveChat,
} from "services/api";
import { IoIosArrowDown } from "react-icons/io";
import CustomSelect from "components/CustomSelect";
import AAITranscriber from "services/aai-transcriber";
import DeepgramTranscriber from "services/deepgram-transcriber";
import { IoIosArrowForward } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import QuestionInput from "components/QuestionInput";

const models = [
  { id: "assembly-ai", name: "AssemblyAI" },
  { id: "deepgram", name: "Deepgram" },
];

export default function LiveTranscription({
  assistant,
  setChatHistory,
  processModel,
  state,
  setState,
  summaryDisabled,
  setSummary,
  threadIdRef,
  isAnswering,
  setIsAnswering,
}) {
  const [transcript, setTranscript] = useState("");
  const [transcriptModel, setTranscriptModel] = useState("assembly-ai");
  const [anchorEl, setAnchorEl] = useState(null);
  const transcriberRef = useRef(null);
  const boxRef = useRef(null);
  const { notifyError } = useContext(AppContext);
  const authToken = localStorage.getItem("authToken");
  const navigate = useNavigate();

  let message = "";
  switch (state) {
    case "halting":
      message = "Connecting...";
      break;
    case "recording":
      message = "Recording...";
      break;
    case "stopping":
      message = "Closing connection...";
      break;
    default:
      message = "Click Below To Speak";
  }

  const checkQuestion = async (message) => {
    const data = await checkQuestionThread(
      message,
      threadIdRef.current,
      processModel
    ).catch((e) => notifyError(e.message, 4000));

    if (data.questionDetected) {
      setIsAnswering(true);
      const { question } = data;
      setChatHistory((prev) => [...prev, { question, assistant }]);
    }
  };

  const getTranscriber = async () => {
    if (transcriptModel === "assembly-ai") {
      const token = await getAAIToken().catch((e) =>
        notifyError(e.message, 4000)
      );
      transcriberRef.current = new AAITranscriber(token);
    } else if (transcriptModel === "deepgram") {
      const token = await getDeepgramToken().catch((e) =>
        notifyError(e.message, 4000)
      );
      transcriberRef.current = new DeepgramTranscriber(token);
    }
  };

  const startRecording = async () => {
    if (!authToken) {
      notifyError("Login is required to use this feature.", 4000);
      navigate("/login");
      return;
    }

    setState("halting");
    setTranscript("");

    const texts = {};
    await getTranscriber();

    if (!transcriberRef.current) {
      setState("idle");
      return;
    }

    transcriberRef.current.listen.ontranscript = async (message) => {
      setTranscript("");
      boxRef.current.scrollTop = boxRef.current.scrollHeight;
      texts[message.start] = message.text;
      const keys = Object.keys(texts);
      keys.sort((a, b) => a - b);

      for (const key of keys) {
        if (texts[key]) {
          setTranscript((prev) => (prev += `${texts[key]}\n`));
        }
      }
    };

    transcriberRef.current.listen.onfinal = checkQuestion;

    await transcriberRef.current.getMicrophone().catch((e) => {
      if (e.name === "NotAllowedError") {
        notifyError("Microphone access is required to use this feature.", 4000);
      } else if (e.name === "NotFoundError") {
        notifyError("No microphone detected.", 4000);
      }
      setState("idle");
    });

    await transcriberRef.current
      .connect()
      .then(() => setState("recording"))
      .catch((e) => {
        notifyError(e.message, 4000);
        setState("idle");
      });
  };

  const stopRecording = async (event) => {
    event.preventDefault();

    setState("stopping");
    await transcriberRef.current.close();
    transcriberRef.current = null;

    const summary = await saveChat(
      transcript,
      threadIdRef.current,
      processModel
    ).catch((e) => notifyError(e.message, 4000));

    setSummary(summary);
    setState("idle");
  };

  const handleAskManualQuestion = (question) => {
    setChatHistory((prev) => [...prev, { question, assistant }]);
    setState("idle");
  };

  useEffect(() => {
    if (state === "empty") {
      setTranscript("");
    }
  }, [state]);

  // Leaving this in for testing purposes.
  const doNothing = () => {
    console.warn("Skipping question");
  };

  useEffect(() => {
    if (transcriberRef?.current?.listen) {
      if (isAnswering) {
        transcriberRef.current.listen.onfinal = doNothing;
      } else {
        transcriberRef.current.listen.onfinal = checkQuestion;
      }
    }
  }, [isAnswering]);

  return (
    <Box sx={styles.root}>
      <Box
        sx={styles.selectTrigger}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <Typography fontSize="24px" fontWeight={700}>
          Live Transcript
        </Typography>

        <IoIosArrowDown size="20px" />
      </Box>

      <CustomSelect
        value={transcriptModel}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        options={models}
        onSelect={({ id }) => setTranscriptModel(id)}
        onClose={() => setAnchorEl(null)}
      />

      <Box sx={styles.transcriptBox} ref={boxRef}>
        {!transcript ? (
          <EmptyState text={message} />
        ) : (
          <Typography
            variant="h7"
            fontWeight={700}
            sx={{ whiteSpace: "pre-wrap" }}
          >
            {transcript}
          </Typography>
        )}
      </Box>

      {state === "recording" ? (
        <Button
          variant="contained"
          color="error"
          onClick={stopRecording}
          startIcon={<Box sx={styles.stopIcon} />}
          sx={styles.recordButton}
        >
          Stop
        </Button>
      ) : (
        <LoadingButton
          variant="contained"
          sx={styles.recordButton}
          loading={state === "halting" || state === "stopping"}
          onClick={startRecording}
          startIcon={state !== "halting" && <PiMicrophoneBold />}
        >
          Start
        </LoadingButton>
      )}

      <Divider>
        <Typography variant="body" color="gray.400" fontWeight={700}>
          OR
        </Typography>
      </Divider>

      <QuestionInput askManualQuestion={handleAskManualQuestion} />

      <Box sx={styles.summaryButtonBox}>
        <Button
          onClick={() => setState("view-summary")}
          endIcon={<IoIosArrowForward />}
          disabled={summaryDisabled}
          sx={styles.summaryButton}
        >
          Generate Summary
        </Button>
      </Box>
    </Box>
  );
}
